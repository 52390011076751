import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home/Home";
import AboutPage from "./pages/AboutPage/AboutPage";
import CoursesPage from "./pages/CoursesPage/CoursePage";
import ContactPage from "./pages/ContactPage/ContactPage";
import TeamPage from "./pages/TeamPage/TeamPage";
import EventPage from "./pages/EventPage/EventPage";
import Certicaficate from "./pages/EventPage/Certicaficate";
import SeminarPage from "./pages/EventPage/SeminarPage";
import FunctionPage from "./pages/EventPage/FunctionPage";
import Form from "./components/Forms/Form";


import "./App.css";



const App = () => {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/courses" element={<CoursesPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/team" element={<TeamPage />} />
          <Route path="/events" element={<EventPage />} />
          <Route path="/certificate" element={<Certicaficate />} />
          <Route path="/seminar" element={<SeminarPage />} />
          <Route path="/functions" element={<FunctionPage />} />
          <Route path="/registration" element={<Form/>} />
        </Routes>
      </Router>
    </>
  );
};

export default App;
