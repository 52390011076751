import React, { useState } from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  const [showPreloader, setShowPreloader] = useState(true);

  // Simulate loading completion after a delay
  setTimeout(() => {
    setShowPreloader(false);
  }, 3000);
  return (
    <>
      <div
        className="container-fluid bg-dark text-light footer pt-5 mt-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container py-5">
          <div className="row g-5">
            <div className="col-lg-3 col-md-6">
              <h4 className="text-white mb-3">Quick Link</h4>
              <Link className="btn btn-link" to="/about">
                About Us
              </Link>
              <Link className="btn btn-link" to="/contact">
                Contact Us
              </Link>
              <Link className="btn btn-link" to="/courses">
                Courses
              </Link>
              <Link className="btn btn-link" to="/team">
                Our Team
              </Link>
              <Link className="btn btn-link" to="/events">
                Our Events
              </Link>
            </div>
            <div className="col-lg-3 col-md-6">
              <h4 className="text-white mb-3">Contact</h4>
              <p className="mb-2">
                <i className="fa fa-map-marker-alt me-3" />
                Near Sohna Service Station Kali pul Service Road Kasur
              </p>
              <p className="mb-2">
                <i className="fa fa-phone-alt me-3" />+ 92(303)0017672
              </p>
              <p className="mb-2">
                <i className="fa fa-phone-alt me-3" />
                +92(309)8896695
              </p>
              <p className="mb-2">
                <i className="fa fa-envelope me-3" />
                expertb12345@gmail.com
              </p>
              <div className="d-flex pt-2">
                <Link
                  className="btn btn-outline-light btn-social"
                  to="https://x.com/novasinctech"
                  target="_blank"
                  
                >
                  <i className="fab fa-twitter" />
                </Link>
                <Link
                  className="btn btn-outline-light btn-social"
                  to="https://www.facebook.com/profile.php?id=100067608463118"
                  target="_blank"
                
                >
                  <i className="fab fa-facebook-f" />
                </Link>
                <Link
                  className="btn btn-outline-light btn-social"
                  to="https://www.instagram.com/expert_b901/"
                  target="_blank"
                  
                >
                  <i className="fab fa-instagram" />
                </Link>
                <Link
                  className="btn btn-outline-light btn-social"
                  to="https://www.linkedin.com/company/novasinc-technologies/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-linkedin-in" />
                </Link>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <h4 className="text-white mb-3">Gallery</h4>
              <div className="row g-2 pt-2">
                <div className="col-4">
                  <img
                    className="img-fluid bg-light p-1"
                    src="img/course-1.jpg"
                    alt="Course 1"
                  />
                </div>
                <div className="col-4">
                  <img
                    className="img-fluid bg-light p-1"
                    src="img/course-2.jpg"
                    alt="Course 2"
                  />
                </div>
                <div className="col-4">
                  <img
                    className="img-fluid bg-light p-1"
                    src="img/course-3.jpg"
                    alt="Course 3"
                  />
                </div>
                <div className="col-4">
                  <img
                    className="img-fluid bg-light p-1"
                    src="img/course-2.jpg"
                    alt="Course 2"
                  />
                </div>
                <div className="col-4">
                  <img
                    className="img-fluid bg-light p-1"
                    src="img/course-3.jpg"
                    alt="Course 3"
                  />
                </div>
                <div className="col-4">
                  <img
                    className="img-fluid bg-light p-1"
                    src="img/course-1.jpg"
                    alt="Course 1"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <h4 className="text-white mb-3">Newsletter</h4>
              <p>
                Subscribe to Our Newsletter for the Latest Updates and Insights
              </p>
              <div
                className="position-relative mx-auto"
                style={{ maxWidth: 400 }}
              >
                <input
                  className="form-control border-0 w-100 py-3 ps-4 pe-5"
                  type="text"
                  placeholder="Your email"
                />
                <button
                  type="button"
                  className="btn btn-primary py-2 position-absolute top-0 end-0 mt-2 me-2"
                >
                  SignUp
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="copyright">
            <div className="row">
              <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
                ©{" "}
                <Link className="border-bottom" to="https://expertb.org" target="_blank">
                  ExpertB Computers
                </Link>
                , All Right Reserved.
              
              </div>
              <div className="col-md-6 text-center text-md-end">
                <div className="footer-menu">
                  <Link to="/">Home</Link>
                  <Link to="/about">About</Link>
                  <Link to="/courses">Courses</Link>
                  <Link to="/contact">Contact</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showPreloader && <div id="preloader"></div>}
    </>
  );
};

export default Footer;
