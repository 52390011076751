import React from "react";
import Header from "../../components/Header/Header";
import Header1 from "../../components/Header/Header1";
import Gallery from "../../components/Gallery/Gallery";
import Footer from "../../components/Footer/Footer";

function EventPage() {
  return (
    <>
      <Header />
      <Header1 />
      <Gallery />
      <Footer />
    </>
  );
}

export default EventPage;
