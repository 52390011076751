import React from "react";
import Header from "../../components/Header/Header";
import Header1 from "../../components/Header/Header1";
import Team from "../../components/Team/Team";
import Footer from "../../components/Footer/Footer";

function TeamPage() {
  return (
    <>
      <Header />
      <Header1 />
      <Team />
      <Footer />
    </>
  );
}

export default TeamPage;
