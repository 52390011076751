import React from 'react';
import './Gallery.css'; 
import { Link } from 'react-router-dom';

const Gallery = () => {

    const events = [
        {
            title: 'Certification',
            image: 'img/certification.jpg',
            link: '/certificate'
        },
        {
            title: 'Seminar',
            image: 'img/seminars/1.jpg',
            link: '/seminar'
        },
        {
            title: 'Events & Functions',
            image: 'img/functions/1.jpg',
            link: '/functions'
        }
    ];
    return (
        <section className="gallery">
            <div className="container">
            
                <div className="row">
                    {events.map((event, index) => (
                        <div key={index} className="col-lg-4 col-md-6 mb-4">
                            <div className="card">
                                <Link to={event.link}>
                                    <img src={event.image} className="card-img-top" alt={event.title} />
                                </Link>
                                <div className="card-body text-center">
                                    <h5 className="card-title">{event.title}</h5>
                                  
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Gallery;
