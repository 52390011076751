import React from "react";
import Header from "../../components/Header/Header";

import Certification from "../../components/Gallery/Certification";
import Footer from "../../components/Footer/Footer";

function Certicaficate() {
  return (
    <>
      <Header />
      <Certification />
      <Footer />
    </>
  );
}

export default Certicaficate;
