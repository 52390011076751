import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const About = () => {
  const location = useLocation();

  return (
    <div className="container-xxl py-5">
      <div className="container">
        <div className="row g-5">
          <div
            className="col-lg-6 wow fadeInUp"
            data-wow-delay="0.1s"
            style={{ minHeight: '200px' }}
          >
            <div className="position-relative h-100">
              <img
                className="img-fluid position-absolute h-100 w-100"
                src="img/front.jpg"
                alt=""
                style={{ objectFit: 'cover', width: '100%', height: '30vh' }}
              />
            </div>
          </div>
          <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
            <h6 className="section-title bg-white text-start text-primary pe-3">About Us</h6>
            <h1 className="mb-4">Welcome to ExpertB</h1>
            <p className="mb-4">
            We believe in the power of collaboration to drive innovation. As your trusted partner, we're committed to turning your visionary ideas into reality.
            </p>
            <p className="mb-4">
            We provider specialized educational institution offering a diverse range of concise and focused courses. These courses are designed to provide targeted knowledge and skills in a short span of time
            </p>
            <div className="row gy-2 gx-4 mb-4">
              <div className="col-sm-6">
                <p className="mb-0">
                  <i className="fa fa-arrow-right text-primary me-2"></i>Skilled Instructors
                </p>
              </div>
              <div className="col-sm-6">
                <p className="mb-0">
                  <i className="fa fa-arrow-right text-primary me-2"></i>Online Classes
                </p>
              </div>
              <div className="col-sm-6">
                <p className="mb-0">
                  <i className="fa fa-arrow-right text-primary me-2"></i>Best Quality Education
                </p>
              </div>
              <div className="col-sm-6">
                <p className="mb-0">
                  <i className="fa fa-arrow-right text-primary me-2"></i>Trusted Professionals
                </p>
              </div>
              <div className="col-sm-6">
                <p className="mb-0">
                  <i className="fa fa-arrow-right text-primary me-2"></i>Serve the Best
                </p>
              </div>
              <div className="col-sm-6">
                <p className="mb-0">
                  <i className="fa fa-arrow-right text-primary me-2"></i>International Certificate
                </p>
              </div>
            </div>
            {/* Conditionally render the "Read More" button */}
            {location.pathname !== '/about' && (
              <Link className="btn btn-primary py-3 px-5 mt-2" to="/about">
                Read More
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
