import React from "react";

const seminars = [
  { src: "img/seminars/1.jpg", alt: "seminar 1" },
  { src: "img/seminars/39.jpg", alt: "seminar 39" },
  { src: "img/seminars/38.jpg", alt: "seminar 38" },
  { src: "img/seminars/2.jpg", alt: "seminar 2" },
  { src: "img/seminars/3.jpg", alt: "seminar 3" },
  { src: "img/seminars/4.jpg", alt: "seminar 4" },
  { src: "img/seminars/5.jpg", alt: "seminar 5" },
  { src: "img/seminars/6.jpg", alt: "seminar 6" },
  { src: "img/seminars/16.jpg", alt: "seminar 16" },
  { src: "img/seminars/17.jpg", alt: "seminar 17" },
  { src: "img/seminars/18.jpg", alt: "seminar 18" },
  { src: "img/seminars/19.jpg", alt: "seminar 19" },
  { src: "img/seminars/20.jpg", alt: "seminar 20" },
  { src: "img/seminars/21.jpg", alt: "seminar 21" },
  { src: "img/seminars/25.jpg", alt: "seminar 25" },
  { src: "img/seminars/26.jpg", alt: "seminar 26" },
  { src: "img/seminars/28.jpg", alt: "seminar 28" },
  { src: "img/seminars/30.jpg", alt: "seminar 30" },
  { src: "img/seminars/31.jpg", alt: "seminar 31" },
  { src: "img/seminars/32.jpg", alt: "seminar 32" },
  { src: "img/seminars/33.jpg", alt: "seminar 33" },
  { src: "img/seminars/34.jpg", alt: "seminar 34" },
  { src: "img/seminars/35.jpg", alt: "seminar 35" },
  { src: "img/seminars/36.jpg", alt: "seminar 36" },
  { src: "img/seminars/7.jpg", alt: "seminar 7" },
  { src: "img/seminars/8.jpg", alt: "seminar 8" },
  { src: "img/seminars/9.jpg", alt: "seminar 9" },
  { src: "img/seminars/10.jpg", alt: "seminar 10" },
  { src: "img/seminars/11.jpg", alt: "seminar 11" },
  { src: "img/seminars/12.jpg", alt: "seminar 12" },
  { src: "img/seminars/13.jpg", alt: "seminar 13" },
  { src: "img/seminars/14.jpg", alt: "seminar 14" },
  { src: "img/seminars/15.jpg", alt: "seminar 15" },

  // Add more seminars as needed
];

const Seminar = () => {
  return (
    <section
      className="gallery"
      style={{ padding: "50px 0", backgroundColor: "#f8f9fa" }}
    >
      <div className="container">
        <h2
          style={{
            fontSize: "2.5rem",
            marginBottom: "40px",
            textAlign: "center",
            fontWeight: "bold",
            color: "#333",
          }}
        >
          Seminars
        </h2>
        <div className="row">
          {seminars.map((seminar, index) => (
            <div
              key={index}
              className="col-lg-4 col-md-6"
              style={{ marginBottom: "30px" }}
            >
              <img
                src={seminar.src}
                alt={seminar.alt}
                style={{
                  borderRadius: "15px",
                  width: "100%",
                  height: "300px",
                  objectFit: "cover",
                  cursor: "pointer",
                }}
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Seminar;
