import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

const Header1 = () => {
  const location = useLocation(); // Get the current route location
  const [headerText, setHeaderText] = useState("");
  const [headerClass, setHeaderClass] = useState("");

  useEffect(() => {
    // Set the header text and background class based on the current route
    switch (location.pathname) {
      case "/about":
        setHeaderText("About");
        setHeaderClass("about");
        break;
      case "/contact":
        setHeaderText("Contact");
        setHeaderClass("contact");
        break;
      case "/courses":
        setHeaderText("Courses");
        setHeaderClass("courses");
        break;
      case "/team":
        setHeaderText("Our Team");
        setHeaderClass("team");
        break;

      case "/events":
        setHeaderClass("event");
        setHeaderText("Our Events");
        break;
        
      default:
        setHeaderText("About"); // Default text for other routes
        setHeaderClass("about"); // Default class for other routes
        break;
    }
  }, [location.pathname]); // Update when the route changes

  return (
    <div className={`container-fluid bg-primary py-5 mb-5 page-header ${headerClass}`}>
      <div className="container py-5">
        <div className="row justify-content-center">
          <div className="col-lg-10 text-center">
            <h1 className="display-3 text-white animated slideInDown">
              {headerText}
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header1;
