import React from "react";
import Header from "../../components/Header/Header";
import Header1 from "../../components/Header/Header1";
import Services from "../../components/Services/Services";
import About from "../../components/About/About";
import Team from "../../components/Team/Team";
import Footer from "../../components/Footer/Footer";

function AboutPage() {
  return (
    <>
      <Header />
      <Header1 />
      <Services />
      <About />
      <Team />
      <Footer />
    </>
  );
}

export default AboutPage;
