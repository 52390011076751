import React from "react";
import { Link } from "react-router-dom";

const Team = () => (
  <div className="container-xxl py-5">
    <div className="container">
      <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
        <h6 className="section-title bg-white text-center text-primary px-3">
          Instructors
        </h6>
        <h1 className="mb-5">Expert Instructors</h1>
      </div>
      <div className="row g-4">
        {[
          {
            id: 1,
            img: "img/abid.jpg",
            name: "Abid Rafique",
            designation: "CEO & Founder",
            fb: "https://www.facebook.com/profile.php?id=100008540505567",
            insta: "https://www.instagram.com/abidmehar.1/",
            lin: "",
          },
          {
            id: 2,
            img: "img/yasir.jpg",
            name: "Yasir Mahmood",
            designation: "Web Developer",
            fb: "https://www.facebook.com/profile.php?id=100047665453784",
            insta: "https://www.instagram.com/yasir_venture/",
            lin: "https://www.linkedin.com/in/yasirmahmood124/",
          },
          {
            id: 3,
            img: "img/usman.jpg",
            name: "Usman Asghar",
            designation: "Python Expert",
            fb: "https://www.facebook.com/Malikusman46",
            insta: "https://www.instagram.com/musmankkh/",
            lin: "https://www.linkedin.com/in/muhammad-usman-asghar/",
          },
          {
            id: 4,
            img: "img/adeel.jpg",
            name: "Adeel Ahmad",
            designation: "Web Developer",
            fb: "https://www.facebook.com/adeel.anxari.1",
            insta: "https://www.instagram.com/adeel.ahmad1021/",
            lin: "https://www.linkedin.com/in/adeelahmad1021/",
          },
  
       
          {
            id: 5,
            img: "img/haiqa.jpg",
            name: "Haiqa Abbas",
            designation: "Forex Trader",
            
          },
      
       
        ].map((instructor, index) => (
          <div
            className={`col-lg-3 col-md-6 wow fadeInUp`}
            data-wow-delay={`${0.1 * (index + 1)}s`}
            key={instructor.id}
          >
            <div className="team-item bg-light">
              <div className="overflow-hidden">
                <img className="img-fluid" src={instructor.img} alt="" />
              </div>
              <div
                className="position-relative d-flex justify-content-center"
                style={{ marginTop: "-23px" }}
              >
                <div className="bg-light d-flex justify-content-center pt-2 px-1">
                  <Link className="btn btn-sm-square btn-primary mx-1" to={instructor.fb} target="_blank">
                    <i className="fab fa-facebook-f" />
                  </Link>
                  <Link className="btn btn-sm-square btn-primary mx-1" to={instructor.lin}  target="_blank">
                    <i className="fab fa-linkedin" />
                  </Link>
                  <Link className="btn btn-sm-square btn-primary mx-1" to={instructor.insta}  target="_blank">
                    <i className="fab fa-instagram" />
                  </Link>
                </div>
              </div>
              <div className="text-center p-4">
                <h5 className="mb-0">{instructor.name}</h5>
                <small>{instructor.designation}</small>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
);

export default Team;
