import React from "react";
const Services = () => {
  return (
    <div className="container-xxl py-5">
      <div className="container">
        <div className="row g-4">
          <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.1s">
            <div className="service-item text-center pt-3">
              <div className="p-4">
                <i className="fa fa-3x fa-graduation-cap text-primary mb-4"></i>
                <h5 className="mb-3">Skilled Instructors</h5>
                <p className="skills">
                  Our team of skilled instructors brings a wealth of knowledge
                  and experience to the table. Each instructor is a master in
                  their field, dedicated to empowering you with the skills and
                  insights needed to succeed
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.3s">
            <div className="service-item text-center pt-3">
              <div className="p-4">
                <i className="fa fa-3x fa-globe text-primary mb-4"></i>
                <h5 className="mb-3">Hands-On Training</h5>
                <p>
                  At ExpertB, we believe in learning by doing. Our instructors
                  provide hands-on training, guiding you through practical
                  exercises and real-world projects to help you build a strong
                  portfolio and gain practical experience.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.5s">
            <div className="service-item text-center pt-3">
              <div className="p-4">
                <i className="fa fa-3x fa-home text-primary mb-4"></i>
                <h5 className="mb-3">On-Going Support</h5>
                <p>
                  Our instructors are committed to your long-term success,
                  offering ongoing support, answering questions, and providing
                  resources to help you continue growing even after the course
                  ends.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.7s">
            <div className="service-item text-center pt-3">
              <div className="p-4">
                <i className="fa fa-3x fa-book-open text-primary mb-4"></i>
                <h5 className="mb-3">Personalized Learning</h5>
                <p>
                  We understand that every student has unique learning needs.
                  Our skilled instructors offer personalized guidance and
                  mentorship, tailoring lessons to your pace and style of
                  learning for maximum impact.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
