import React from "react";
import Header from "../../components/Header/Header";
import Carousel from "../../components/Carousel/Carousel";
import About from "../../components/About/About";
import Courses from "../../components/Courses/Courses";
import Footer from "../../components/Footer/Footer";
const Home = () => {
  return (
    <>
      <Header />
      <Carousel />
      <About />
      <Courses />
      <Footer />
    </>
  );
};

export default Home;
