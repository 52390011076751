import React from "react";
const events = [
  { src: "img/functions/1.jpg", alt: "function 1" },
  { src: "img/functions/2.jpg", alt: "function 2" },
  { src: "img/functions/3.jpg", alt: "function 3" },
  { src: "img/functions/4.jpg", alt: "function 4" },
  { src: "img/functions/5.jpg", alt: "function 5" },
  { src: "img/functions/6.jpg", alt: "function 6" },
  { src: "img/functions/7.jpg", alt: "function 7" },
  { src: "img/functions/8.jpg", alt: "function 8" },
  { src: "img/functions/9.jpg", alt: "function 9" },
  { src: "img/functions/10.jpg", alt: "function 10" },
  { src: "img/functions/11.jpg", alt: "function 11" },
  { src: "img/functions/12.jpg", alt: "function 12" },

  // Add more functions as needed
];

function Events() {
  return (
    <section
      className="gallery"
      style={{ padding: "50px 0", backgroundColor: "#f8f9fa" }}
    >
      <div className="container">
        <h2
          style={{
            fontSize: "2.5rem",
            marginBottom: "40px",
            textAlign: "center",
            fontWeight: "bold",
            color: "#333",
          }}
        >
          Events & Functions
        </h2>
        <div className="row">
          {events.map((funct, index) => (
            <div
              key={index}
              className="col-lg-4 col-md-6"
              style={{ marginBottom: "30px" }}
            >
              <img
                src={funct.src}
                alt={funct.alt}
                style={{
                  borderRadius: "15px",
                  width: "100%",
                  height: "300px",
                  objectFit: "cover",
                  cursor: "pointer",
                }}
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Events;
