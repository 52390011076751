import React from "react";
import Header from "../../components/Header/Header";
import Header1 from "../../components/Header/Header1";
import Courses from "../../components/Courses/Courses";
import Testimonial from "../../components/Testimonial/Testimonial";
import Footer from "../../components/Footer/Footer";

function CoursePage() {
  return (
    <>
      <Header />
      <Header1 />
      <Courses />
      <Testimonial />
      <Footer />
    </>
  );
}

export default CoursePage;
