import React from 'react';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import 'animate.css';
import OwlCarousel from 'react-owl-carousel';
import { Link } from 'react-router-dom';

const Carousel = () => {
    const options = {
        items: 1,
        loop: true,
        nav: false,
        dots: true,
        autoplay: true,
        autoplayTimeout: 5000,
        animateOut: 'fadeOut',
        animateIn: 'fadeIn',
    };

    return (
        <div className="container-fluid p-0 mb-5">
            <OwlCarousel className="header-carousel position-relative" {...options}>
                <div className="owl-carousel-item position-relative">
                    <img className="img-fluid" src="img/homebg.jpg" alt="Carousel 1" />
                    <div
                        className="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center"
                        style={{ background: 'rgba(24, 29, 56, .7)' }}
                    >
                        <div className="container">
                            <div className="row justify-content-start">
                                <div className="col-sm-10 col-lg-8">
                                    <h5 className="text-primary text-uppercase mb-3 animate__animated animate__slideInDown">
                                        Welcome to ExpertB
                                    </h5>
                                    <h1 className="display-3 text-white animate__animated animate__slideInDown">
                                    Learn from Industry Experts
                                    </h1>
                                    <p className="fs-5 text-white mb-4 pb-2">
                                    Our experienced instructors provide hands-on training with real-world projects. Gain the knowledge and skills needed to excel in the rapidly evolving tech industry. Enroll now and be job-ready
                                    </p>
                                    <Link
                                        to="/about"
                                        className="btn btn-primary py-md-3 px-md-5 me-3 animate__animated animate__slideInLeft"
                                    >
                                        Read More
                                    </Link>
                                    <Link
                                        to="/registration"
                                        className="btn btn-light py-md-3 px-md-5 animate__animated animate__slideInRight"
                                      
                                    >
                                        Enroll Now
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="owl-carousel-item position-relative">
                    <img className="img-fluid" src="img/homebg1.jpg" alt="Carousel 2" />
                    <div
                        className="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center"
                        style={{ background: 'rgba(24, 29, 56, .7)' }}
                    >
                        <div className="container">
                            <div className="row justify-content-start">
                                <div className="col-sm-10 col-lg-8">
                                    <h5 className="text-primary text-uppercase mb-3 animate__animated animate__slideInDown">
                                        Best Courses
                                    </h5>
                                    <h1 className="display-3 text-white animate__animated animate__slideInDown">
                                    Transform Your Passion Into a Profession
                                    </h1>
                                    <p className="fs-5 text-white mb-4 pb-2">
                                    Turn your love for technology into a rewarding career. Our comprehensive courses cover everything from basic computer skills to advanced programming and IT certifications. Start your professional journey with us!
                                    </p>
                                    <Link
                                        to="/about"
                                        className="btn btn-primary py-md-3 px-md-5 me-3 animate__animated animate__slideInLeft"
                                    >
                                        Read More
                                    </Link>
                                    <Link
                                        to="/registration"
                                        className="btn btn-light py-md-3 px-md-5 animate__animated animate__slideInRight"
                                   
                                    >
                                        Enroll Now
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="owl-carousel-item position-relative">
                    <img className="img-fluid" src="img/certification.jpg" alt="Carousel 2" />
                    <div
                        className="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center"
                        style={{ background: 'rgba(24, 29, 56, .7)' }}
                    >
                        <div className="container">
                            <div className="row justify-content-start">
                                <div className="col-sm-10 col-lg-8">
                                    <h5 className="text-primary text-uppercase mb-3 animate__animated animate__slideInDown">
                                        Best Courses
                                    </h5>
                                    <h1 className="display-3 text-white animate__animated animate__slideInDown">
                                    Certification That Makes a Difference
                                    </h1>
                                    <p className="fs-5 text-white mb-4 pb-2">
                                    Boost your career with certifications that are recognized by employers worldwide. Our accredited courses provide you with the credentials needed to stand out in the job market. Get certified and get noticed!
                                    </p>
                                    <Link
                                        to="/about"
                                        className="btn btn-primary py-md-3 px-md-5 me-3 animate__animated animate__slideInLeft"
                                    >
                                        Read More
                                    </Link>
                                    <Link
                                        to="/registration"
                                        className="btn btn-light py-md-3 px-md-5 animate__animated animate__slideInRight"
                                
                                    >
                                        Enroll Now
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="owl-carousel-item position-relative">
                    <img className="img-fluid" src="img/certificates/11.jpg" alt="Carousel 2" />
                    <div
                        className="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center"
                        style={{ background: 'rgba(24, 29, 56, .7)' }}
                    >
                        <div className="container">
                            <div className="row justify-content-start">
                                <div className="col-sm-10 col-lg-8">
                                    <h5 className="text-primary text-uppercase mb-3 animate__animated animate__slideInDown">
                                        Best Courses
                                    </h5>
                                    <h1 className="display-3 text-white animate__animated animate__slideInDown">
                                    Connect, Learn, and Grow with Our Community
                                    </h1>
                                    <p className="fs-5 text-white mb-4 pb-2">
                                    Join a vibrant community of learners, tech enthusiasts, and professionals. Network, collaborate, and grow together. Our academy provides a supportive environment for you to thrive and achieve your goals
                                    </p>
                                    <Link
                                        to="/about"
                                        className="btn btn-primary py-md-3 px-md-5 me-3 animate__animated animate__slideInLeft"
                                    >
                                        Read More
                                    </Link>
                                    <Link
                                        to="/"
                                        className="btn btn-light py-md-3 px-md-5 animate__animated animate__slideInRight"
                                    >
                                        Join Now
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </OwlCarousel>
        </div>
    );
};

export default Carousel;
