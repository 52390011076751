import React from "react";
import "./Form.css";

function Form() {
  return (
    <div class="container form-container">
      <h2>Admission Open</h2>
      <form>
        <div class="mb-3">
          <label for="name" class="form-label">
            Name
          </label>
          <input
            type="text"
            class="form-control"
            id="name"
            placeholder="Enter your full name"
            required
          />
        </div>

        <div class="mb-3">
          <label for="fatherName" class="form-label">
            Father Name
          </label>
          <input
            type="text"
            class="form-control"
            id="fatherName"
            placeholder="Enter your father's name"
            required
          />
        </div>

        <div class="mb-3">
          <label for="email" class="form-label">
            Email
          </label>
          <input
            type="email"
            class="form-control"
            id="email"
            placeholder="Enter your email address"
            required
          />
        </div>

        <div class="mb-3">
          <label for="contact" class="form-label">
            Contact Number
          </label>
          <input
            type="tel"
            class="form-control"
            id="contact"
            placeholder="Enter your contact number"
            required
          />
        </div>

        <div class="mb-3">
          <label for="address" class="form-label">
            Address
          </label>
          <textarea
            class="form-control"
            id="address"
            rows="3"
            placeholder="Enter your address"
            required
          ></textarea>
        </div>

        <div class="mb-3">
          <label for="course" class="form-label">
            Courses (Select One)
          </label>
          <select class="form-select" id="course" required>
            <option selected disabled>
              Choose a course
            </option>
            <option value="forex">Forex Trading</option>
            <option value="web3m">Web Development (3 Months)</option>
            <option value="web6m">Web Development (6 Months)</option>
            <option value="graphics">Graphics Designing (3 Months)</option>
            <option value="excel">Advanced Excel (3 Months)</option>
            <option value="freelancing">Freelancing (1 Month)</option>
            <option value="smm">Social Media Marketing (3 Months)</option>
            <option value="content">Content Writing (1 Month)</option>
          </select>
        </div>

        <div class="mb-3">
          <label for="reference" class="form-label">
            Reference
          </label>
          <input
            type="text"
            class="form-control"
            id="reference"
            placeholder="How did you hear about us?"
            required
          />
        </div>

        <button type="submit" class="btn btn-primarys">
          Submit Application
        </button>
      </form>
    </div>
  );
}

export default Form;
