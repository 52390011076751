import React from "react";
import Header from "../../components/Header/Header";
import Header1 from "../../components/Header/Header1";
import Footer from "../../components/Footer/Footer";
import Contact from "../../components/Contact/Contact";

function ContactPage() {
  return (
    <>
      <Header />
      <Header1 />
      <Contact />
      <Footer />
    </>
  );
}

export default ContactPage;
