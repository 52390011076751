import React from 'react'
import Header from '../../components/Header/Header'
import Seminar from '../../components/Gallery/Seminar'
import Footer from '../../components/Footer/Footer'

function SeminarPage() {
  return (
   <>
   <Header />
   <Seminar />
   <Footer />
   </>
  )
}

export default SeminarPage;