import React from 'react';


const certificates = [
  { src: 'img/certificates/3.jpg', alt: 'Certificate 1' },
    { src: 'img/certification.jpg', alt: 'Certificate 2' },
    { src: 'img/certificates/1.jpg', alt: 'Certificate 3' },
    { src: 'img/certificates/2.jpg', alt: 'Certificate 4' },
    { src: 'img/certificates/4.jpg', alt: 'Certificate 5' },
    { src: 'img/certificates/5.jpg', alt: 'Certificate 6' },
    { src: 'img/certificates/6.jpg', alt: 'Certificate 7' },
    { src: 'img/certificates/7.jpg', alt: 'Certificate 8' },
    { src: 'img/certificates/8.jpg', alt: 'Certificate 9' },
    { src: 'img/certificates/9.jpg', alt: 'Certificate 10' },
    { src: 'img/certificates/10.jpg', alt: 'Certificate 11' },
    { src: 'img/certificates/11.jpg', alt: 'Certificate 12' },
    { src: 'img/certificates/12.jpg', alt: 'Certificate 13' },
    { src: 'img/certificates/13.jpg', alt: 'Certificate 14' },
    { src: 'img/certificates/14.jpg', alt: 'Certificate 15' },

    // Add more certificates as needed
];

const CertificatesGallery = () => {
    return (
        <section className="gallery" style={{ padding: '50px 0', backgroundColor: '#f8f9fa' }}>
            <div className="container">
                <h2 style={{
                    fontSize: '2.5rem',
                    marginBottom: '40px',
                    textAlign: 'center',
                    fontWeight: 'bold',
                    color: '#333'
                }}>
                    Certificates
                </h2>
                <div className="row">
                    {certificates.map((certificate, index) => (
                        <div key={index} className="col-lg-4 col-md-6" style={{ marginBottom: '30px' }}>
                            <img 
                                src={certificate.src} 
                                alt={certificate.alt} 
                                style={{
                                    borderRadius: '15px',
                                    width: '100%',
                                    height: '300px',
                                    objectFit: 'cover',
                                    cursor: 'pointer'
                                }} 
                            />
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default CertificatesGallery;
