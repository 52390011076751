import React from "react";
import Header from "../../components/Header/Header";
import Events from "../../components/Gallery/Events";
import Footer from "../../components/Footer/Footer";

function FunctionPage() {
  return (
    <>
      <Header />
      <Events />
      <Footer />
    </>
  );
}

export default FunctionPage;