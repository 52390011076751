import React from "react";
import { Link } from "react-router-dom";

const Courses = () => (
  <div className="container-xxl py-5">
    <div className="container">
      <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
        <h6 className="section-title bg-white text-center text-primary px-3">Courses</h6>
        <h1 className="mb-5">Popular Courses</h1>
      </div>
      <div className="row g-4 justify-content-center">
        {[
          {
            id: 1,
            img: "img/course-1.jpg",
         
            title: "Web Design & Development Course for Beginners",
            link: "/registration",
           
          },
          {
            id: 2,
            img: "img/course-2.jpg",
            title: "Graphics Design Course for Beginners",
            link: "/registration",
       
          },
          {
            id: 3,
            img: "img/course-3.jpg",
            title: "Digital Marketing Course for Beginners",
            link: "/registration",
        
          },
          {
            id: 4,
            img: "img/course-4.jpg",
            title: "E-commerce Development Course for Beginners",
            link: "/registration",
           
          },
          {
            id: 5,
            img: "img/course-5.jpg",
            title: "Forex Trading Course for Beginners",
            link: "/registration",
        
          },
          {
            id: 6,
            img: "img/course-6.jpg",
            title: "Amazon Web Services (AWS) Training Course",
            link: "/registration",
      
          },
          {
            id: 7,
            img: "img/course-7.jpg",
            title: "Search Engine Optimization (S.E.0) Course",
            link: "/registration",
      
          },
          {
            id: 8,
            img: "img/course-8.jpg",
            title: "Advanced Excel Training Course for Beginners",
            link: "/registration",
      
          },
          {
            id: 9,
            img: "img/course-9.jpg",
            title: "Avanced Freelancing Training Course",
            link: "/registration",
      
          },
        ].map((course, index) => (
          <div className={`col-lg-4 col-md-6 wow fadeInUp`} data-wow-delay={`${0.1 * (index + 1)}s`} key={course.id}>
            <div className="course-item bg-light">
              <div className="position-relative overflow-hidden">
                <img className="img-fluid" src={course.img} alt="" />
                <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                 
                  <Link
                    to={course.link}
                    className="flex-shrink-0 btn btn-sm btn-primary px-3"
                    style={{ borderRadius: "20px" }}
                   
                  >
                    Join Now
                  </Link>
                </div>
              </div>
              <div className="text-center p-4 pb-0">
                
            
                <h5 className="mb-4">{course.title}</h5>
              </div>
            
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
);

export default Courses;
