import React from "react";
import { Link, useLocation } from "react-router-dom";

const Header = () => {
  const location = useLocation();

  return (
    <nav className="navbar navbar-expand-lg bg-white navbar-light shadow sticky-top p-0">
      <Link
        to="/"
        className="navbar-brand d-flex align-items-center px-4 px-lg-5"
      >
        <img
          src="img/logo.png"
          alt="Logo"
          className="img-fluid"
          style={{ height: "8vh", width: "100px" }}
        />
      </Link>
      <button
        type="button"
        className="navbar-toggler me-4"
        data-bs-toggle="collapse"
        data-bs-target="#navbarCollapse"
      >
        <span className="navbar-toggler-icon" />
      </button>
      <div className="collapse navbar-collapse" id="navbarCollapse">
        <div className="navbar-nav ms-auto p-4 p-lg-0">
          <Link
            to="/"
            className={`nav-item nav-link ${
              location.pathname === "/" ? "active" : ""
            }`}
          >
            Home
          </Link>
          <Link
            to="/about"
            className={`nav-item nav-link ${
              location.pathname === "/about" ? "active" : ""
            }`}
          >
            About
          </Link>
          <Link
            to="/courses"
            className={`nav-item nav-link ${
              location.pathname === "/courses" ? "active" : ""
            }`}
          >
            Courses
          </Link>
          <Link
            to="/team"
            className={`nav-item nav-link ${
              location.pathname === "/team" ? "active" : ""
            }`}
          >
            Our Team
          </Link>
          <Link
            to="/events"
            className={`nav-item nav-link ${
              location.pathname === "/events" ? "active" : ""
            }`}
          >
            Event
          </Link>
          <Link
            to="/contact"
            className={`nav-item nav-link ${
              location.pathname === "/contact" ? "active" : ""
            }`}
          >
            Contact
          </Link>
        </div>
        <Link to="/registration" className="btn btn-primary py-4 px-lg-5 d-none d-lg-block">
          Enroll Now
          <i className="fa fa-arrow-right ms-3" />
        </Link>
      </div>
    </nav>
  );
};

export default Header;
